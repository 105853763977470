.custom-button button{
    border: 2px solid #707070;
    /* margin-left: 20px; */
    background: #fff;
    padding: 5px 20px;
    cursor: pointer;
}

.custom-button{
    margin-top: 20px;
    /* text-align: right; */
}
