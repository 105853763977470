.items-radio-options button{
    background: #fff;
    width: 40px;
    height: 40px;
    margin: 5px 10px;
    border: 1px solid #707070;
    cursor: pointer;
}

.items-radio-options button.selected{
    background: #D0D0D0 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
}

.items-radio-options{
    margin-top: 20px;
    text-align: center;
}