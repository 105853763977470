.layout{
    position: relative;
}
.header{
    width: 100%;
    height: 10vh;
    background: #E0E0E0 0% 0% no-repeat padding-box;
    opacity: 1;
}
.content{
    overflow-y: scroll;
    padding-bottom: 20px;
    margin: 0 auto;
    width: 75%;
    height: 85vh;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 46px #00000029;
    opacity: 1;
}