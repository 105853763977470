.file-box-container{
    background: #F2F2F2 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    height: 120px;
    width: 100%;
    padding: 0 10px 0 10px;
    text-align: -webkit-center;
}

.file-box-container button{
    margin-top: 20px;
    padding: 5px 10px;
    display: block;
    cursor: pointer;
}