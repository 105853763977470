@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

:root {
    --current-color: #000000;
    --font-playfair: 'Playfair Display';
    --current-font: 'Roboto', sans-serif;
    --gray-bg: #426cb40d;
    --light-gray-bg: #F7F7F7;
    --extra-light-gray-bg: #F3F4F4;
    --gray-color: #ABABAB;
    --border-color: #dddddd;
    --theme-color-1: #426CB4;
    --theme-color-light-2: #EDA53A;
    --theme-color-dark-2: #EF5219;
    --theme-color-3: #10B8B8;
    --pink-color: #FEF3EF;
    --current-linear-color: linear-gradient(#EF9D03 , #E87208);
    --orange-3 : #F36C42;
}

* {
    outline: 0;
}

body {
    color: var(--current-color);
    font-family: var(--current-font);
}

footer {
    overflow: hidden;
}

.theme-color-1 {
    color: var(--theme-color-1) !important;
}

.theme-color-2 {
    color: var(--theme-color-light-2) !important;
}

.theme-color-dark-2 {
    color: var(--theme-color-dark-2) !important;
}

.theme-color-3 {
    color: var(--theme-color-3) !important;
}

.o3 {
    color: var(--orange-3) !important;
}

.black-text {
    color: var(--current-color) !important;
}

.elgb {
    background-color: var(--extra-light-gray-bg) !important;
}

.gb {
    background-color: var(--gray-bg) !important;
}

.bb {
    background-color: var(--theme-color-1) !important;
}

.pb {
    background-color: var(--pink-color) !important;
}

.fm {
    font-family: var(--current-font) !important;
}

.fp {
    font-family: var(--font-playfair) !important;
}

a {
    text-decoration: none !important;
}

h1.title {
    font-size: 42px;
    margin-bottom: 20px;
}

h2.title {
    font-size: 32px;
}

h1.title,
h2.title,
h3.title {
    font-family: var(--font-playfair);
    font-weight: 600;
}

.hiw-wrapp .o3,
h2.title,
h3.title {
    background: -webkit-linear-gradient(#EF9D03, #E87208);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

h1.title {
    color: var(--theme-color-dark-2);
}

h3.title {
    font-size: 15px;
}

h2.title.text-white {
    background: -webkit-linear-gradient(#ffffff, #ffffff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

h4.title {
    font-size: 13px;
    font-family: var(--current-font);
    font-weight: bold;
}

.text-md,
.arrow-style-list li,
.accordion-button {
    font-size: 18px !important;
    font-family: var(--current-font);
}

.text-sm {
    font-size: 11px !important;
    font-family: var(--current-font);
    color: var(--gray-color);
}

.gap {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
}

.gap-top {
    padding-top: 50px !important;
}

.gap-bottom {
    padding-bottom: 50px !important;
}

.gap-top-sm {
    padding-top: 25px !important;
}

.gap-bottom-sm {
    padding-bottom: 25px !important;
}

.cover {
    background-size: cover !important;
}

.no-repeat {
    background-repeat: no-repeat !important;
}

.heading{
    text-align: center;
    font: normal normal bold 22px/31px Helvetica Neue;
    letter-spacing: 0px;
    color: #707070;
}

.label{
    margin: 25px 0 15px 0;
    display: block;
    text-align: left;
    font: normal normal normal Helvetica Neue;
    letter-spacing: 0px;
    color: #707070;
}

.info{
    margin-top: 20px;
    text-align: left;
    font: normal normal normal Helvetica Neue;
    letter-spacing: 0px;
    color: #707070;
}

.form-content{
    margin: 25px 25px;
}

.box-message{
    text-align: left;
    font: normal normal normal Helvetica Neue;
    letter-spacing: 0px;
    color: #707070;
    border: 1px solid #707070;
    padding: 20px;
}

.header-wrapper ul {
    padding: 0;
    margin-bottom: 0;
}

.header-wrapper ul li {
    list-style: none;
    display: inline-block;
    padding: 0 30px;
}

.header-wrapper ul li a {
    text-decoration: none;
    display: block;
    color: var(--current-color);
    font-weight: 600;
    font-family: var(--current-font);
}

.header-wrapper ul li a.active {
    color: var(--orange-3);
}

.header-wrapper {
    position: fixed;
    z-index: 5;
    top: 0;
    left: 0;
    background-color: rgba(234 234 234 / 70%);
    width: 100%;
}

.header-wrapper.fixed-top {
    background-color: rgba(234 234 234 / 90%);
}

.btn-w {
    text-align: center;
    padding: 12px 40px;
    text-align: center;
    font-family: var(--current-font);
    font-weight: 600;
    display: inline-block;
    min-width: 130px;
    transition: .3s;
    font-size: 13px;
}

.btn-w:hover {
    animation: pulse 1s ;
}

.t-carousel .btn-w.sm:hover {
    animation: none;
}

.btn-w.sm {
    font-size: 12px;
}

.btn-w.text-start {
    padding-left: 15px;
}

.btn-w.color1 {
    background-color: var(--theme-color-1);
    color: #ffffff;
}

.btn-w.color2 {
    background-color: var(--theme-color-light-2);
    color: #ffffff;
}

.btn-w.color3 {
    background-color: var(--theme-color-3);
    color: #ffffff;
}

.btn-w.black {
    background-color: var(--current-color);
    color: #ffffff;
}

.btn-w.gray {
    background-color: var(--gray-bg);
    color: var(--current-color);
}

.home-banner-wrapp {
    width: 100%;
    height: 100vh;
    background-position: center;
}

.site-logo img {
    width: 100px;
}

p.p-md {
    font-size: 18px !important;
    color: var(--current-color);
    margin-bottom: 0;
    font: var(--current-font);
}

.home-banner-wrapp p.p-md {
    color: var(--current-color);
}

.home-banner-wrapp > .container {
    height: 100%;
}

.h-banner-form > div {
    background-color: var(--light-gray-bg);
    padding: 40px;
    -moz-box-shadow: 0 9px 30px 25px rgb(0 0 0 / 2%);
    -webkit-box-shadow: 0 9px 30px 25px rgb(0 0 0 / 2%);
    box-shadow: 0 9px 30px 25px rgb(0 0 0 / 2%);
}

.form-block {
    position: relative;
    margin-bottom: 30px;
}

.form-block .field-icon {
    position: absolute;
    top: 5px;
    left: 0;
    z-index: 1;
}

.form-block input,
.form-block textarea,
.form-block select {
    padding: 10px 20px;
    border: 1px solid var(--current-color);
    color: var(--gray-color);
    width: 100%;
    font-family: var(--current-font);
    font-weight: 600;
    background-color: transparent;
}

textarea {
    min-height: 100px;
}

select {
    -webkit-appearance: none;
    appearance: none;
  }

  .select-wrapper {
    position: relative;
  }
  
  .select-wrapper::after {
    content: "";
    background-image: url('../../../public/img/down-blue-arrow.png');
    background-repeat: no-repeat;
    width: 13px;
    height: 8px;
    top: 50%;
    right: 15px;
    position: absolute;
    display: block;
    transform: translateY(-50%);
  }

.form-block.only-bottom-border input {
    border: 0;
    border-bottom: 1px solid var(--current-color);
    padding-top: 0;
    padding-left: 0;
}

.cb-form-cnt-block .form-block input, 
.cb-form-cnt-block .form-block textarea, 
.cb-form-cnt-block .form-block select {
    border: 1px solid var(--theme-color-light-2);
}

.cb-form-cnt-block .form-block.only-bottom-border input {
    border: 0;
    border-bottom: 1px solid var(--theme-color-light-2);
}

.form-block.with-icon input {
    padding-left: 40px;
}

::-webkit-input-placeholder { /* Edge */
    color: var(--gray-color);
    font-family: var(--current-font);
    font-weight: 600;
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: var(--gray-color);
    font-family: var(--current-font);
    font-weight: 600;
  }
  
  ::placeholder {
    color: var(--gray-color);
    font-family: var(--current-font);
    font-weight: 600;
  }

  button {
    border: 0;
  }

.ft {
    font-weight: 600;
    color: var(--current-color);
    margin-left: 15px;
    font-family: var(--current-font);
}

.banner-frame > div {
    padding: 15px;
    background-color: rgba(255 255 255 / 35%);
}

.frame-block {
    width: 33%;
    border-right: 1px solid #c5c5c5;
    padding: 5px 0;
    display: flex;
    justify-content: space-between;
}

.frame-block:nth-last-child(1) {
    border-right: 0;
}

.frame-block > div {
    margin: 0 auto;
    width: 78%;
}

.theme-down-arrow {
    display: block;
    width: 100%;
    animation: bouncearrow 1600ms infinite cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

.cc-block div:nth-child(1) {
    width: 80%;
    border-right: 1px solid var(--theme-color-light-2)
}

.cc-block div:nth-child(2) {
    width: 20%;
}

.cc-block {
    border: 1px solid var(--theme-color-light-2);
    flex-grow: 1;
    margin-right: 20px;
}

.cc-block:nth-last-child(1) {
    margin-right: 0;
}

.cc-block div {
    padding: 10px;
    text-align: center;
}

.cc-image-block img {
    width: 20px;
}

.theme-down-arrow img {
    width: 25px;
}

.cb-block > div {
    padding: 15px;
    background-color: #ffffff;
    height: 100%;
    transition: .5s ease;
}

.cb-block > div:hover {
    -moz-box-shadow: 0 4px 15px 0px rgb(0 0 0 / 11%);
    -webkit-box-shadow: 0 4px 15px 0px rgb(0 0 0 / 11%);
    box-shadow: 0 4px 15px 0px rgb(0 0 0 / 11%);
}

.cb-icon-block {
    background-color: var(--theme-color-1);
    padding: 20px 10px;
    margin-right: 15px;
    display: flex;
    align-self: flex-start;
}

p {
    color: var(--current-color);
    font-family: var(--current-font);
    font-weight: 600;
}

.cb-block p:nth-last-child(1) {
    margin-bottom: 0;
}

.cb-block {
    margin-bottom: 15px;
}

table {
    width: 100%;
    border-collapse: collapse;
}

table th,
table td {
    padding: 15px;
    font-weight: 600;
    border-right: 1px solid var(--border-color);
}

table th {
    font-family: var(--font-playfair);
    font-size: 14px;
    color: var(--orange-3);
    background-color: #E9E8E8;
}

table td {
    font-family: var(--current-font);
}

table tr:nth-of-type(even) {
    background-color: #F5F5F5;
}

tr td:nth-last-child(1),
tr th:nth-last-child(1) {
    border-right: 0;
}

.ct-image-block img {
    max-width: 100%;
}

.blur-effect {
    position: relative;
}

.blur-effect::after {
    content: '';
    background-color: rgba(255, 255, 255, .3);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 2;
}

.blur-effect > .row {
    position: relative;
    z-index: 3;
}

.circle {
    background-color: var(--theme-color-1);
    border-radius: 100%;
    position: absolute;
    z-index: 1;
}

.circle.lg {
    width: 162px !important;
    height: 162px;
    top: -69px;
    right: 70px;
}

.circle.md.orange.left,
.circle.md.blue.left {
    width: 96px !important;
    height: 96px;
    top: 50px;
    right: auto;
    left: 0;
    background-color: var(--theme-color-light-2);
    filter: blur(45px);
}

.circle.md.orange.right,
.circle.md.blue.right {
    width: 96px !important;
    height: 96px;
    top: 50px;
    right: 0;
    left: auto;
    background-color: var(--theme-color-light-2);
    filter: blur(45px);
}

.circle.md.orange.right.center,
.circle.md.blue.right.center {
    top: 50%;
    transform: translateY(-50%);
}

.circle.md.orange.right.bottom,
.circle.md.blue.right.bottom {
    top: auto;
    bottom: 50px;
}

.circle.md.orange.left.center,
.circle.md.blue.left.center {
    top: 50%;
    transform: translateY(-50%);
}

.circle.md.orange.left.bottom,
.circle.md.blue.left.bottom {
    top: auto;
    bottom: 50px;
}

.circle.md.blue.left,
.circle.md.blue.right {
    background-color: var(--theme-color-1);
}

.circle.sm {
    width: 42px;
    height: 42px;
    top: -54px;
    right: 0;
}

.arrow-style-list li {
    list-style: none;
    position: relative;
    padding-left: 40px;
    width: 33.3333%;
    margin-bottom: 15px;
    font-weight: 600;
}

.scenarios-wrapp .arrow-style-list li {
    font-weight: normal;
}

.sub-list li {
    padding-left: 25px;
}

.sub-list li::before {
    display: none;
}

.sub-list li::after {
    content: '';
    position: absolute;
    top: 9px;
    left: 8px;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background: var(--current-linear-color);
}

.arrow-style-list.w-of-li-100 li {
    width: 100%;
}

.arrow-style-list li::before {
    content: '';
    background-image: url('../../../public/img/f-down-arrow-white.png');
    background-repeat: no-repeat;
    position: absolute;
    top: 5px;
    left: 0;
    width: 16px;
    height: 16px;
}

.arrow-style-list.orange-icon li::before {
    content: '';
    background-image: url('../../../public/img/down-orange-arrow.png');
}

.arrow-style-list.circle-arrow li::before {
    background-image: url('../../../public/img/circle-blue-arrow.svg');
    width: 26px;
    height: 26px;
    top: 0;
}

.arrow-style-list.circle-arrow.info-list li::before {
    background-image: url('../../../public/img/cross-circle.png');
    width: 27px;
    height: 27px;
}

.scen-image-block img {
    border: 5px solid #ffffff;
    box-shadow: -8px 1px 17px 2px --orange-354;
}

.arrow-style-list.li-100 li {
    width: 100%;
}

.max-w-100 {
    max-width: 100% !important;
}

.t-carousel .slick-item > div {
    background-color: #ffffff;
    padding: 40px 20px 0 50px;
    position: relative;
    -moz-box-shadow: 0 4px 15px 0px rgb(0 0 0 / 11%);
    -webkit-box-shadow: 0 4px 15px 0px rgb(0 0 0 / 11%);
    box-shadow: 0 4px 15px 0px rgb(0 0 0 / 11%);
}

.via-t-pilot {
    margin-left: 50px;
}

.t-carousel .slick-item > div::before {
    content: '';
    background-image: url('../../../public/img/comma-symbol.svg');
    background-repeat: no-repeat;
    position: absolute;
    top: 31px;
    left: 15px;
    width: 28px;
    height: 23px;
}

.t-carousel .slick-slide > div {
    margin-right: 15px;
}

.slick-next, 
.slick-prev {
    padding: 0;
    top: -55px !important;
    right: 15px !important;
    width: 42px !important;
    height: 42px !important;
    background-color: var(--theme-color-3) !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.slick-next.slick-disabled::after,
.slick-prev.slick-disabled::after {
    opacity: .5;
}

.slick-prev {
    right: 50px !important;
    left: auto !important;
}

.slick-next::before, 
.slick-prev::before {
    display: none;
}

.slick-next::after, 
.slick-prev::after {
    content: '&lt';
    border: solid #ffffff;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
}

.slick-next::after {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.slick-prev::after {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}

.cb-form-cnt-block > div {
    background-color: #ffffff;
    padding: 30px;
    -moz-box-shadow: 0 4px 27px 0px rgb(0 0 0 / 3%);
    -webkit-box-shadow: 0 4px 27px 0px rgb(0 0 0 / 3%);
    box-shadow: 0 4px 27px 0px rgb(0 0 0 / 3%);
}

footer ul {
    margin-bottom: 0;
    padding: 0;
}

footer ul li {
    list-style: none;
}

footer ul li a {
    color: #ffffff !important;
    font-size: 13px;
}

footer h4 {
    margin-bottom: 20px;
    color: #ffffff;
}

.footer-s-links img {
    margin-right: 10px;
}

.fb {
    padding-top: 20px;
    border-top: 1px solid #ffffff;
    margin-top: 40px;
}

.footer-s-links a {
    margin-right: 20px;
}

.header-wrapper ul li:nth-last-child(1) {
    padding-right: 0;
}

.ip-banner-wrapp  {
    width: 100%;
    height: 400px;
}

.accordion-button {
    border: 0 !important;
    outline: 0 !important;
    box-shadow: none !important;
    background-color: var(--gray-bg) !important;
    color: var(--theme-color-1) !important;
    border-radius: 0 !important;
}

.accordion-item {
    border: 0 !important;
    border-radius: 0 !important;
    margin-bottom: 10px;
}

.accordion .accordion-button::after {
    background-image: url(../../../public/img/down-blue-arrow.png) !important;
    width: 13px !important;
    height: 8px !important;
    background-size: contain !important;
}

.accordion-body,
.accordion-button {
    padding-left: 20px !important;
    background-color: #EAEAEA !important;
}

.accordion-body p {
    margin-bottom: 0;
}

.b-title {
    background-color: var(--theme-color-1);
    color: #ffffff;
    padding: 10px 30px;
    text-align: center;
    font-weight: 600;
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
}

.b-title a {
    color: #ffffff !important;
}

.ip-banner-wrapp {
    position: relative;
}

.fdc-title-block {
    background-color: var(--light-gray-bg);
    padding: 20px;
}

.fdc-form {
    background-color: #ffffff;
    padding: 20px 50px;
}

.form-block label {
    font-weight: 600;
    font-family: var(--current-font);
    margin-bottom: 5px;
}

.fdc-form-wrapp {
    width: 70%;
    margin: 0 auto;
}

.fdc-shadow-block {
    -moz-box-shadow: 0 4px 27px 0px rgb(0 0 0 / 3%);
    -webkit-box-shadow: 0 4px 27px 0px rgb(0 0 0 / 3%);
    box-shadow: 0 4px 27px 0px rgb(0 0 0 / 3%);
}

.gif-input .form-block input,
.gif-input .form-block select,
.gif-input .form-block textarea {
    background-color: var(--light-gray-bg);
    border: 0;
}

.stepper-block .sb-image-block {
    width: 41px;
    height: 50px;
    background-color: var(--gray-color);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-bottom: 20px;
}

.stepper-block.active .sb-image-block {
    background-color: var(--theme-color-1);
}

.fdc-stepper-wrapp {
    display: flex;
    margin-bottom: 20px;
}

.stepper-block {
    text-align: center;
    position: relative;
    width: 33.333333%;
    z-index: 2;
}

.stepper-block::before,
.stepper-block::after {
    content: '';
    width: 43%;
    height: 2px;
    position: absolute;
    top: 25px;
    right: 0;
    background-color: var(--gray-color);
    display: block;
    z-index: -1;
}

.stepper-block.active.filled-after::after,
.stepper-block.active.filled-before::before {
    background-color: var(--theme-color-1);
}

.stepper-block::after {
    top: 25px;
    right: 0;
}

.stepper-block::before {
    top: 25px;
    left: 0;
}

.stepper-block:nth-child(1)::before,
.stepper-block:nth-last-child(1)::after {
    display: none;
}

.dd-wrapp {
    border: 1px dotted var(--current-color);
    padding: 50px;
    background-color: var(--light-gray-bg);
}

.dropzone-content {
    font-weight: 600;
}

.dd-image-block {
    margin-bottom: 10px;
}

.cp {
    cursor:  pointer !important;
}

.dd-files ul li{
    list-style: none;
}

.sig-canvas {
    width: 100%;
    height: 184px;
    background-color: #FBFBFB;
    border: 1px solid var(--current-color);
}

.checkbox input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }
  
  .checkbox label {
    position: relative;
    cursor: pointer;
    font-weight: 600;
    display: flex;
    align-items: start;
  }
  
  .checkbox label:before {
    content:'';
    -webkit-appearance: none;
    background-color: transparent;
    border: 1px solid var(--gray-color);
    background-color: var(--gray-bg);
    box-shadow: none;
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
  }
  
  .checkbox input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 4px;
    left: 4px;
    width: 14px;
    height: 14px;
    background-color: var(--current-color);
  }

  .congratulation-block {
    padding-top: 200px;
    padding-bottom: 100px;
  }

  .fdc-form.congratulation-block.text-center {
    border-bottom: 10px solid var(--theme-color-1);
}

.flight-icon-1,
.flight-icon-2 {
    position: absolute;
    top: 20px;
}

.flight-icon-1 {
    left: 0;
}

.flight-icon-2 {
    right: 0;
}

.error {
    margin-top: 5px;
    color: red;
}

.cc-block:hover .cc-image-block img {
    filter: brightness(0) invert(1);
}

.cc-block:hover .theme-color-1 {
    color:  #ffffff !important;
}

.cc-block:hover {
    background-color: var(--theme-color-light-2);
}

.cc-block {
    transition: .5s ease;
}

.cc-frame > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.cc-frame > div > div {
    background-color: #ffffff;
    width: 18%;
    padding: 25px 10px;
    transition: .5s ease;
    border: 1px solid transparent;
}

.cc-frame > div > div:hover {
    -webkit-box-shadow: 0 4px 4px 0px rgb(0 0 0 / 25%);
    -moz-box-shadow: 0 4px 4px 0px rgb(0 0 0 / 25%);
    box-shadow: 0 4px 4px 0px rgb(0 0 0 / 25%);
    border: 1px solid var(--theme-color-light-2);
}

.cc-tiles-wrapp,
.cc-content-wrapp {
    background-color: var(--gray-bg);
}

.hiw-wrapp .stepper-block.active .sb-image-block,
.stepper-block.active.filled-after::after, 
 .stepper-block.active.filled-before::before {
    background: var(--current-linear-color);
}

.hiw-wrapp .stepper-block .sb-image-block {
    width: 30px;
    height: 30px;
    margin-bottom: 20px;
    border-radius: 100%;
    color: #ffffff;
}

.hiw-wrapp .stepper-block::before, 
.hiw-wrapp .stepper-block::after {
    top: 14px;
    width: 45%;
    height: 1px;
 }

 .hiw-tiles-image-block {
    width: 80px;
    height: 80px;
    border-radius: 100%;
    border: 1px solid var(--theme-color-light-2);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
 }

 .hiw-tiles > [class*="col"] {
    border-right: 1px solid var(--theme-color-light-2);
}

.hiw-tiles > [class*="col"]:nth-last-child(1) {
    border-right: 0;
}

.dividwr {
    margin: 30px 0;
    background-color: var(--theme-color-light-2);
    width: 100%;
    height: 1px;
}

.hiw-tiles > [class*="col"]  {
    padding-right: 60px;
    padding-left: 60px;
}

.center-block::before {
    content: '';
    background: linear-gradient(217deg, rgba(239 157 3 / 78%), rgba(232 114 8 / 78%));
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.h-contact-info > div:nth-of-type(1) {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--current-color);
}

.h-contact-info a {
    color: var(--current-color) !important;
    font-weight: 600;
}

.atc-icon {
    width: 30%;
}

.cc-frame > div .atc-icon img {
    max-width: 100%;
    transition: .5s ease;
}

.cc-frame > div .atc-icon:hover {
    background-color: #dddddd;
}

.cc-frame > div .atc-icon:hover img {
    transform: rotate(45deg);
}

.cc-frame > div > div .atc-link img {
    transition: .5s ease;
}

.cc-frame > div > div:hover .atc-link img {
    transform: rotate(-45deg);
}

.dt-wrapp > div:nth-child(1) {
    padding-bottom: 200px;
}

.fct-2-img-block img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.dt-table {
    background-color: #ffffff;
    height: 100%;
    -webkit-box-shadow: 0 4px 31px 0px rgba(245 143 81 / 29%);
    -moz-box-shadow: 0 4px 31px 0px rgba(245 143 81 / 29%);
    box-shadow: 0 4px 31px 0px rgba(245 143 81 / 29%);
}

.dt-wrapp > div:nth-child(2) {
    -webkit-box-shadow: 0 1px 4px 0px rgb(0 0 0 / 25%);
    -moz-box-shadow: 0 1px 4px 0px rgb(0 0 0 / 25%);
    box-shadow: 0 1px 4px 0px rgb(0 0 0 / 25%);
}

.hiw-wrapp {
    -webkit-box-shadow: -125px 14px 30px 0px rgb(0 0 0 / 6%);
    -moz-box-shadow: -125px 14px 30px 0px rgb(0 0 0 / 6%);
    box-shadow: -125px 14px 30px 0px rgb(0 0 0 / 6%);
}

.cc-frame > div > div:nth-child(4) .atc-icon:hover img,
.cc-frame > div > div:nth-child(5) .atc-icon:hover img {
    transform: scale(1.2);
}

footer {
    background: linear-gradient(#EF9D03 , #E87208);
}

  



















.ft {
    font-size: 13px;
}

p,
table td {
    font-size: 11px;
}



@keyframes bouncearrow {
    50% {
        transform: translateY(-15px);
      }
    }

    @keyframes pulse {
        0% {
          transform: scale(1);
        }
        70% {
          transform: scale(.9);
        }
          100% {
          transform: scale(1);
        }
      }





@media(min-width: 767px) {
    .dt-width {width: 96.666667% !important;}
    .min-width-block {min-width: 138px;}
}

@media(min-width: 991px) {
    .mobile-menu {display: none;}
    .header-wrapper ul.sm {display: none;}
    .gft {margin-top: 81px;}
    .fdc-form-wrapp {margin-top: 100px;}
}

@media(max-width: 991px) {
    .text-md, 
    .arrow-style-list li,
    .accordion-button {
        font-size: 15px !important;
    }

    .accordion-body, 
    .accordion-button {
        padding-left: 15px !important;
    }

    h1.title {font-size: 32px;}
    h2.title {font-size: 26px;}
    h3.title {font-size: 14px;}
    h4.title {font-size: 12px;}
    .w-100-sm {width: 100%;}
    .mobile-menu {font-size: 24px; background-color: transparent;}
    .header-wrapper ul.lg {display: none;}
    .arrow-style-list li {width: 50%;}
    .footer-s-links a {margin-right: 0;}
    .home-banner-wrapp {height: 100%;}
    .h-banner-cnt {padding-top: 50px; padding-bottom: 30px;}
    .fdc-form-wrapp {width: 100%;}
    .fdc-form {padding: 15px 15px;}
    .header-wrapper ul {width: 100%; top: 0; left: 0; position: fixed; background-color: #ffffff; height: 100vh; display: block; padding-top: 50px;}
    .header-wrapper ul li {display: block; width: 100%; padding: 25px 30px;}
    .close-menu {position: absolute; top: 0; right: 16px; text-align: right; font-size: 26px;}
    .atc-icon {margin: 0 auto;}
    .cc-frame > div > div {width: 47%; text-align: center; margin-bottom: 20px;}
    .hiw-tiles-image-block {margin: 0 auto; margin-bottom: 20px;}
    .hiw-tiles-content {text-align: center;}
    .header-wrapper {position: sticky;}
}


@media(max-width: 767px) {
    .arrow-style-list li,
    .frame-block,
    .frame-block > div {
        width: 100%;
    }

    table th, 
    table td {
        padding: 10px;
    }

    .frame-block br {
        display: none;
    }

    .text-md, 
    .arrow-style-list li,
    .accordion-button {
        font-size: 14px !important;
    }

    body,
    .fdc-stpper-title,
    .form-block label,
    .btn-w {
        font-size: 12px;
    }

    h1.title {font-size: 28px;}
    h2.title {font-size: 24px;}
    h3.title {font-size: 13px;}
    h4.title {font-size: 11px;}

    .order-1-sm {order: 1 !important;}
    .order-2-sm {order: 2 !important;}
    .cc-frame-block > div {flex-wrap: wrap;}
    .cc-frame-block > div a {width: 100%; margin-bottom: 10px; margin-right: 0;}
    .banner-frame > div {flex-wrap: wrap;}
    .frame-block > div .ft {width: 100%; text-align: center;}
    .frame-block > div .d-flex {flex-wrap: wrap;}
    .frame-block > div {margin-bottom: 20px;}
    .frame-block > div img {margin-bottom: 10px;}
    .mt-sm-30 {margin-top: 30px !important;}
    .theme-down-arrow {display: none;}
    .flight-icon-1 {display: none;}
    .flight-icon-2 {display: none;}
    .circle {display: none;}
    .frame-block {border-right: 0;}
    .dt-wrapp > div :nth-child(1) {background-size: contain !important; background-position: bottom;}
}











